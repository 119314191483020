export const empresas = [
  {
    id: 0,
    url: 'riocaixas',
    nome: 'Rio Caixas ',
  },
  {
    id: 1,
    url: 'realcaixas',
    nome: 'Real  Caixas ',
  },
  {
    id: 2,
    url: 'alvesferreira',
    nome: 'Alves Ferreira ',
  },
  {
    id: 3,
    url: 'ecaixas',
    nome: 'E - Caixas ',
  },
  {
    id: 4,
    url: 'speed',
    nome: 'Speed - Banco de Teste ',
  },
  {
    id: 5,
    url: 'speedoff',
    nome: 'Speed Off - Banco de Teste ',
  },
  {
    id: 6,
    url: 'jacipel',
    nome: 'Jacipel ',
  },
];

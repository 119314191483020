import { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  Box,
  Avatar,
  Stack,
} from '@mui/material';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { socket } from '../../components/Socket';

import {
  useAuthDispatch,
  handleLogin,
  useAuthState,
  handleCheckUser,
  carregarTema,
} from '../../contexts/AuthContext';

import Spinner from '../../components/Spinner/Spinner';
import { Redirect, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { QRCodeSVG } from 'qrcode.react';
import { empresas } from '../../helpers/Empresas';

function Login() {
  const { id, inAuthentication } = useParams();

  const confirm = useConfirm();
  const { autenticado, loading, qrcode } = useAuthState();
  const [urlBackground, setUrlBackGround] = useState('');
  const dispatch = useAuthDispatch();
  const local = window.location.href;
  const [idSocket, setIdSocket] = useState(null);
  const [values, setValues] = useState({
    email: '',
    password: '',
    empresa: '',
    url: id,
    newThema: null,
  });

  const [nomeBotao, setNomeBotao] = useState('Entrar');
  const [error, setError] = useState({
    email: false,
    password: false,
    empresa: false,
  });

  useEffect(() => {
    switch (id) {
      case 'riocaixas':
        carregarTema(dispatch, 0);
        setUrlBackGround(`empresa${0}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 0,
          url: id,
          newThema: 0,
        });

        break;
      case 'realcaixas':
        carregarTema(dispatch, 0);
        setUrlBackGround(`empresa${1}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 1,
          url: id,
          newThema: 0,
        });

        break;
      case 'alvesferreira':
        carregarTema(dispatch, 0);
        setUrlBackGround(`empresa${2}.jpg`);

        setValues({
          email: '',
          password: '',
          empresa: 2,
          url: id,
          newThema: 0,
        });

        break;
      case 'ecaixas':
        carregarTema(dispatch, 1);
        setUrlBackGround(`empresa${3}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 3,
          url: id,
          newThema: 1,
        });

        break;
      case 'speed':
        carregarTema(dispatch, 2);
        setUrlBackGround(`empresa${4}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 4,
          url: id,
          newThema: 2,
        });

        break;
      case 'speedoff':
        carregarTema(dispatch, 2);
        setUrlBackGround(`empresa${4}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 5,
          url: id,
          newThema: 2,
        });

        break;
      case 'jacipel':
        carregarTema(dispatch, 2);
        setUrlBackGround(`empresa${6}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 6,
          url: id,
          newThema: 2,
        });

        break;
      default:
        confirm({
          title: 'Erro ao Carregar Empresa ',
          description: `Nenhuma empresa foi selecionada. Verifique se a URL digitada está correta ou se a empresa está habilitada.( Erro: empresa : ${id}) `,
          confirmationText: 'OK',
          cancellationText: '',
        });
    }
  }, [id, dispatch]);

  /*NOVAS FUNCOES DE LOGIN */

  useEffect(() => {
    try {
      socket.on('connect', () => {
        setIdSocket(inAuthentication ? inAuthentication : socket.id);
        console.log(idSocket);
      });
    } catch (err) {
    } finally {
    }
  }, []);

  useEffect(() => {
    socket.emit('join-room', idSocket);
  }, [inAuthentication]);
  /* FIM NOVAS FUNCOES DE LOGIN */

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleBlur = (prop) => (event) => {
    if (values[prop] === '' || values[prop].isUndefined) {
      setError({ ...error, [prop]: true });
    } else {
      setError({ [prop]: false });
    }
  };

  if (autenticado && qrcode) return <Redirect to="/linhaNova" />;
  if (autenticado && !qrcode) return <Redirect to="/" />;

  async function handleSubmit(e) {
    e.preventDefault();
    if (values.password === '001') {
      handleCheckUser(dispatch, values);
    } else if (values.email && values.password) {
      handleLogin(dispatch, values, inAuthentication);
      setNomeBotao('Carregando...');
    } else {
      setNomeBotao('Entrar');
    }
  }

  let renderForm = loading ? (
    <Stack
      spacing={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Spinner />
    </Stack>
  ) : !inAuthentication ? (
    <Stack
      spacing={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            label="Digite  o seu Login"
            name="email"
            autoComplete="email"
            autoFocus
            error={error.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="senha"
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            label="Digite sua Senha"
            type="password"
            id="senha"
            error={error.password}
            autoComplete="senha"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              color: 'primary.textColor',
            }}
          >
            <LockTwoToneIcon />
            {nomeBotao}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2"></Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2"></Link>
            </Grid>
          </Grid>
        </Box>
        <QRCodeSVG value={`${local}/${idSocket}`} />
        <a href={`${local}/${idSocket}`}>{`${local}/${idSocket}`}</a>
      </>
    </Stack>
  ) : (
    <>
      <Stack spacing={3}>
        {' '}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            label="Digite  o seu Login"
            name="email"
            autoComplete="email"
            autoFocus
            error={error.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="senha"
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            label="Digite sua Senha"
            type="password"
            id="senha"
            error={error.password}
            autoComplete="senha"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              color: 'primary.textColor',
            }}
          >
            <LockTwoToneIcon />
            {nomeBotao}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2"></Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2"></Link>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );

  if (loading) {
    return <Spinner />;
  }
  return (
    <Grid
      container
      component="main"
      sx={{
        height: '97vh',

        margin: 0,
      }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(/images/${urlBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '50%',
          backgroundPosition: 'center',
        }}
      ></Grid>

      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{ bgcolor: 'primary.textColor' }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.buttonInitial' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entrar no sistema
          </Typography>
          {renderForm}
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhotoIcon from '@mui/icons-material/Photo';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';

import { useState } from 'react';
import VisualizarImagem from '../VisualizarImagem/VisualizarImagem';
import api from '../../../../../api/api';
import ConcluirProducao from '../ConcluirProducao/ConcluirProducao';

import { toast } from 'react-toastify';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.tz.setDefault('America/Sao_Paulo');

export default function TabelaResultados({
  listaSemanal,
  concluirEtapa,
  encontrarProximaMaquina,
}) {
  const empresaAtiva = localStorage.getItem('empresaAtiva');
  const [tipoFiltro, setTipoFiltro] = useState('Data');
  const [modalVisualizaImagem, setModalVisualizaImagem] = useState(false);
  const [caixaSelecionada, setCaixaSelecionada] = useState('');
  const [modalConcluirProducao, setModalConculirProducao] = useState(false);
  const [proximaMaquina, setProximaMaquina] = useState('');
  const [producaoSelecionada, setProducaoSelecionada] = useState('');

  const [imagemSelecionada, setImagemSelecionada] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!Array.isArray(listaSemanal)) {
    const chavesObjeto = Object.keys(listaSemanal);

    const somaInicial = 0;
    const novasMaquinas = chavesObjeto;

    const novasoma = novasMaquinas.map((maquina) => {
      const totalMaquina = listaSemanal[maquina].itens.reduce(
        (acc, crr) => acc + crr.quantidade,
        somaInicial
      );
      return totalMaquina;
    });

    const pesoTotal = novasMaquinas.map((maquina) => {
      const totalMaquina = listaSemanal[maquina].itens.reduce(
        (acc, crr) => acc + crr.peso,
        somaInicial
      );
      return totalMaquina;
    });

    const somaAtrasadas = novasMaquinas.map((maquina) => {
      const diaComTimezone = dayjs.tz().startOf('d');
      const totalMaquina = listaSemanal[maquina].itens.filter((item) =>
        dayjs.tz(item.entrega).isBefore(diaComTimezone)
      );
      return totalMaquina.length;
    });

    const somaEmdia = novasMaquinas.map((maquina) => {
      const diaComTimezone = dayjs.tz().startOf('d');
      const totalMaquina = listaSemanal[maquina].itens.filter((item) =>
        dayjs.tz(item.entrega).isSame(diaComTimezone)
      );
      return totalMaquina.length;
    });

    const somaFutura = novasMaquinas.map((maquina) => {
      const diaComTimezone = dayjs.tz().startOf('d');
      const totalMaquina = listaSemanal[maquina].itens.filter((item) =>
        dayjs.tz(item.entrega).isAfter(diaComTimezone)
      );
      return totalMaquina.length;
    });
    function renderCorCelula(entrega) {
      const diaComTimezone = dayjs.tz().startOf('d');
      const dataEntrega = dayjs.tz(entrega);

      if (dataEntrega.isSame(diaComTimezone)) {
        return '#00ff56';
      }
      if (dataEntrega.isBefore(diaComTimezone)) {
        return '#ffc4b7';
      }

      if (dataEntrega.isAfter(diaComTimezone)) {
        return '#00c4ff';
      }
    }
    function renderTotal(index, maquina) {
      const qtdeCaixasArr = novasoma.map((item) => item);
      const pesoTotalArr = pesoTotal.map((item) => item);
      const somaAtrasadasArr = somaAtrasadas;
      const somaEmDiaArr = somaEmdia;
      const somaFuturaArr = somaFutura;
      if (Array.isArray(listaSemanal)) {
        return {
          soma: qtdeCaixasArr[index],
          itens: 0,
        };
      }
      return {
        qtdeCaixas: qtdeCaixasArr[index],
        pesoTotal: pesoTotalArr[index],
        somaAtrasadas: somaAtrasadasArr[index],
        somaEmDia: somaEmDiaArr[index],
        somaFutura: somaFuturaArr[index],
        itens: listaSemanal[maquina]?.itens.length ?? 0,
      };
    }

    function ordenaPorNomeouData(tipo) {
      if (!Array.isArray(listaSemanal)) {
        const chavesObjeto = Object.keys(listaSemanal);
        const novasMaquinas = chavesObjeto;
        setTipoFiltro(tipo);
        if (tipo === 'Nome') {
          const ordensPorNome = novasMaquinas.map((maquina) => {
            const ordens = listaSemanal[maquina].itens.sort((a, b) =>
              a.nomeCliente.localeCompare(b.nomeCliente)
            );
            return ordens;
          });
          return ordensPorNome;
        }
        if (tipo === 'Data') {
          const ordensPorData = novasMaquinas.map((maquina) => {
            const ordens = listaSemanal[maquina].itens.sort(
              (a, b) =>
                new Date(a.entrega).getTime() - new Date(b.entrega).getTime()
            );
            return ordens;
          });
          return ordensPorData;
        }
        return listaSemanal;
      }
    }

    const visualizarImagem = (caixa, cliente) => {
      setLoading(true);
      api
        .post(
          '/imagem/arte',
          { item: caixa, cliente: cliente },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(({ data }) => {
          setImagemSelecionada(data.artes[0].imagem);
        })
        .catch((err) => {
          toast.error('Imagem não encontrada ');
        })
        .finally(() => {
          setLoading(false);
          setModalVisualizaImagem(true);
        });
    };
    const fecharModalVisualizaImagem = () => {
      setModalVisualizaImagem(false);
      setImagemSelecionada(null);
    };

    const fecharModalConcluirProducao = () => {
      setModalConculirProducao(false);
    };
    const aoConcluirEtapa = (producao, dados) => {
      setProximaMaquina(encontrarProximaMaquina(dados));

      setProducaoSelecionada(producao);
      setModalConculirProducao(true);
    };

    return (
      <Box
        sx={{
          width: '100vw',
          maxWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {caixaSelecionada && (
          <VisualizarImagem
            modalVisualizaImagem={modalVisualizaImagem}
            imagemSelecionada={imagemSelecionada}
            fecharModalVisualizaImagem={fecharModalVisualizaImagem}
          />
        )}

        {producaoSelecionada && (
          <ConcluirProducao
            producaoSelecionada={producaoSelecionada}
            modalConcluirProducao={modalConcluirProducao}
            concluirEtapa={concluirEtapa}
            fecharModalConcluirProducao={fecharModalConcluirProducao}
            proximaMaquina={proximaMaquina}
          />
        )}
        {novasMaquinas?.map((maquina, index) => {
          return (
            <Card
              key={index}
              sx={{
                maxWidth: {
                  sm: `calc(100vw/${novasMaquinas.length})`,
                  md: `calc(100vw/${novasMaquinas.length})`,
                  xl: `calc(100vw/${novasMaquinas.length})`,
                  lg: `calc(100vw/${novasMaquinas.length})`,
                },
                width: {
                  md: `calc(100vw/${novasMaquinas.length + 0.05})`,
                  xl: `calc(100vw/${novasMaquinas.length + 0.05})`,
                  lg: `calc(100vw/${novasMaquinas.length + 0.05})`,
                },
                height: { md: '85vh', lg: '85vh', xl: '85vh' },
                maxHeight: { md: '85vh', lg: '85vh', xl: '85vh' },
                p: 0,
                m: 0,
                borderRadius: 0,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                boxShadow:
                  'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  top: `calc(2dvh)`,
                  width: `100%`,
                  maxWidth: `100%`,
                  background: '#ffffff',
                  position: 'sticky',
                  alignItems: 'center',
                  height: { md: 30, lg: 35, xl: 65, xxl: 100 },
                }}
              >
                <img
                  src={
                    listaSemanal[maquina].tipoMaquina !== ''
                      ? `/img/${listaSemanal[maquina].tipoMaquina}.jpg`
                      : `/img/${maquina}.jpg`
                  }
                  style={{ maxWidth: '20%', maxHeight: '100%' }}
                ></img>
                <CardHeader
                  title={maquina}
                  disableTypography
                  sx={{
                    maxHeigth: 30,
                    height: 30,
                    p: 0,
                    m: 0,
                    fontSize: '1rem',
                    fontWeight: 600,
                    textAlign: 'center',
                    backgroundColor: '#ffffff',
                  }}
                />
              </Box>

              <Divider />
              <CardContent
                sx={{
                  width: '98%',
                  maxWidth: '98%',
                  p: 0,
                  m: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  mt: '3dvh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  border: '1px solid #ccc',
                  '::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '::-webkit-scrollbar-track': {
                    backgroundColor: '#f1f1f1',
                  },
                  '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#1976d2',

                    border: '2px solid #f1f1f1',
                  },
                  '::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#0d47a1',
                  },
                }}
              >
                <Box
                  sx={{
                    height: '100dvh',
                    m: 0,
                    p: 0,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Table
                    stickyHeader
                    sx={{
                      maxWidth: '100%',
                      width: '100%',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={12} sx={{ background: '#FFFFFF' }}>
                          <FormControl fullWidth>
                            <Select
                              value={tipoFiltro}
                              onChange={(e) =>
                                ordenaPorNomeouData(e.target.value)
                              }
                            >
                              <MenuItem value={'Nome'}>Nome</MenuItem>
                              <MenuItem value={'Data'}>Data</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 700,
                            width: '10%',
                            maxWidth: '10%',
                            fontSize: {
                              lg: '0.50rem',
                              xl: '0.7rem',
                              xxl: '1.1rem',
                            },
                            m: { lg: 0, xl: 0, xxl: 0 },
                            p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                          }}
                        >
                          Ação
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 700,
                            width: '10%',
                            maxWidth: '10%',
                            fontSize: {
                              lg: '0.50rem',
                              xl: '0.7rem',
                              xxl: '1.1rem',
                            },
                            m: { lg: 0, xl: 0, xxl: 0 },
                            p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                          }}
                        >
                          Data
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '10%',
                            maxWidth: '10%',
                            fontWeight: 700,
                            fontSize: {
                              lg: '0.50rem',
                              xl: '0.7rem',
                              xxl: '1.1rem',
                            },
                            m: { lg: 0, xl: 0, xxl: 0 },
                            p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                          }}
                        >
                          Entr.
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '20%',
                            maxWidth: '20%',
                            fontWeight: 700,
                            fontSize: {
                              lg: '0.50rem',
                              xl: '0.7rem',
                              xxl: '1.1rem',
                            },
                            m: { lg: 0, xl: 0, xxl: 0 },
                            p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                          }}
                        >
                          Cliente
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '20%',
                            maxWidth: '20%',
                            fontWeight: 700,
                            fontSize: {
                              lg: '0.50rem',
                              xl: '0.7rem',
                              xxl: '1.1rem',
                            },
                            m: { lg: 0, xl: 0, xxl: 0 },
                            p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                          }}
                        >
                          Caixa
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 700,
                            width: '10%',
                            maxWidth: '10%',
                            fontSize: {
                              lg: '0.50rem',
                              xl: '0.70rem',
                              xxl: '1.1rem',
                            },
                            m: { lg: 0, xl: 0, xxl: 0 },
                            p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                          }}
                        >
                          Qtde
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 700,
                            width: '10%',
                            maxWidth: '10%',
                            fontSize: {
                              lg: '0.50rem',
                              xl: '0.70rem',
                              xxl: '1.1rem',
                            },
                            m: { lg: 0, xl: 0, xxl: 0 },
                            p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                          }}
                        >
                          Peso
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ p: 0, m: 0 }}>
                      {listaSemanal[maquina].itens.map((dados, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              m: 0,
                              p: 1,
                              background: renderCorCelula(dados.entrega),
                            }}
                          >
                            <TableCell
                              sx={{
                                m: 0,
                                p: { lg: 0.1, xl: 0.2, xxl: 0.5 },
                                width: '17%',
                                maxWidth: '17%',
                                fontSize: {
                                  lg: '0.50rem',
                                  xl: '0.60rem',
                                  xxl: '1rem',
                                },
                                fontWeight: 600,
                                borderRight: '1px solid lightGray',
                              }}
                            >
                              <IconButton onClick={() => visualizarImagem()}>
                                <PhotoIcon
                                  sx={{ color: 'primary.buttonColorSecondary' }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  aoConcluirEtapa(dados.producao, dados)
                                }
                              >
                                <CheckCircleOutlineIcon
                                  sx={{ color: 'primary.buttonColorSecondary' }}
                                />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              sx={{
                                m: 0,
                                p: { lg: 0.1, xl: 0.2, xxl: 0.5 },
                                width: '12%',
                                maxWidth: '12%',
                                fontSize: {
                                  lg: '0.50rem',
                                  xl: '0.60rem',
                                  xxl: '1rem',
                                },
                                fontWeight: 600,
                                borderRight: '1px solid lightGray',
                              }}
                            >
                              {dayjs.tz(dados.data).format('DD/MM')}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 700,
                                p: { lg: 0.1, xl: 0.2, xxl: 0.5 },
                                width: '12%',
                                maxWidth: '12%',
                                borderRight: '1px solid lightGray',
                                fontSize: {
                                  lg: '0.50rem',
                                  xl: '0.60rem',
                                  xxl: '1rem',
                                },
                              }}
                            >
                              {dayjs.tz(dados.entrega).format('DD/MM')}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 700,
                                p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                                width: '25%',
                                maxWidth: '25%',
                                borderRight: '1px solid lightGray',
                                fontSize: {
                                  lg: '0.50rem',
                                  xl: '0.60rem',
                                  xxl: '1rem',
                                },
                              }}
                            >
                              {dados.nomeCliente.substr(0, 12)}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 700,
                                p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                                width: '27%',
                                maxWidth: '27%',
                                borderRight: '1px solid lightGray',
                                textWrap: 'wrap',
                                fontSize: {
                                  lg: '0.50rem',
                                  xl: '0.60rem',
                                  xxl: '1rem',
                                },
                              }}
                            >
                              {dados.caixa.substr(0, 12)}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 700,
                                p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                                borderRight: '1px solid lightGray',
                                width: '12%',
                                maxWidth: '12%',
                                fontSize: {
                                  lg: '0.50rem',
                                  xl: '0.60rem',
                                  xxl: '1rem',
                                },
                              }}
                            >
                              {dados.quantidade?.toLocaleString('pt-BR', {
                                style: 'decimal',
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              })}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 700,
                                p: { lg: 0.1, xl: 0.2, xxl: 0.5 },
                                width: '12%',
                                maxWidth: '12%',
                                fontSize: {
                                  lg: '0.50rem',
                                  xl: '0.60rem',
                                  xxl: '1rem',
                                },
                              }}
                            >
                              {dados.peso.toLocaleString('pt-BR', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell
                          sx={{ textAlign: 'center' }}
                          colSpan={12}
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </CardContent>
              <Box
                sx={{
                  position: 'fixed',
                  maxWidth: {
                    xl: `calc(100vw/${novasMaquinas.length + 0.05})`,
                    lg: `calc(100vw/${novasMaquinas.length + 0.05})`,
                    md: `calc(100vw/${novasMaquinas.length + 0.05})`,
                  },
                  width: {
                    xl: `calc(100vw/${novasMaquinas.length + 0.05})`,
                    lg: `calc(100vw/${novasMaquinas.length + 0.05})`,
                    md: `calc(100vw/${novasMaquinas.length + 0.05})`,
                  },
                  height: 110,
                  bottom: 0,
                  flexDirection: 'column',
                  display: 'flex',
                  p: 0,
                  m: 0,
                }}
              >
                <Box
                  sx={{
                    bgcolor: 'primary.lighter',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: 150,
                      textAlign: 'center',
                      fontWeight: 700,
                      fontSize: {
                        lg: '0.8rem',
                        xl: '0.8rem',
                        xxl: '0.8rem',
                      },
                      m: { lg: 0, xl: 0, xxl: 0 },
                      p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                    }}
                  >
                    CAIXAS
                  </Box>
                  <Box
                    sx={{
                      width: 150,
                      textAlign: 'center',
                      fontWeight: 700,
                      fontSize: {
                        lg: '0.8rem',
                        xl: '0.8rem',
                        xxl: '0.8rem',
                      },
                      m: { lg: 0, xl: 0, xxl: 0 },
                      p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                    }}
                  >
                    PRODUÇÕES
                  </Box>
                  <Box
                    sx={{
                      width: 150,
                      textAlign: 'center',
                      fontWeight: 700,
                      fontSize: {
                        lg: '0.8rem',
                        xl: '0.8rem',
                        xxl: '0.8rem',
                      },
                      m: { lg: 0, xl: 0, xxl: 0 },
                      p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                    }}
                  >
                    PESO TOTAL
                  </Box>
                </Box>
                <Box
                  sx={{
                    bgcolor: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    height: 65,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  <Box
                    sx={{
                      width: 150,
                      textAlign: 'center',
                      fontWeight: 700,
                      fontSize: {
                        lg: '0.8rem',
                        xl: '0.8rem',
                        xxl: '0.8rem',
                      },
                      m: { lg: 0, xl: 0, xxl: 0 },
                      p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                    }}
                  >
                    {renderTotal(index, maquina).qtdeCaixas?.toLocaleString(
                      'pt-BR',
                      {
                        style: 'decimal',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: 150,
                      textAlign: 'center',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      fontWeight: 700,
                      display: 'flex',
                      fontSize: {
                        lg: '0.8rem',
                        xl: '0.8rem',
                        xxl: '0.8rem',
                      },
                      m: { lg: 0, xl: 0, xxl: 0 },
                      p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                    }}
                  >
                    {/*renderTotal(index, maquina).itens*/}
                    <Box
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        fontWeight: 700,
                        display: 'flex',
                        fontSize: {
                          lg: '0.8rem',
                          xl: '0.8rem',
                          xxl: '0.8rem',
                        },
                        m: { lg: 0, xl: 0, xxl: 0 },
                        p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                      }}
                    >
                      <Box
                        sx={{
                          width: '32%',
                          borderRadius: '5px',
                        }}
                      >
                        <img
                          src="/assets/icons/alerta.png"
                          style={{ width: '30px', height: '30px' }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '32%',
                          borderRadius: '5px',
                        }}
                      >
                        <img
                          src="/assets/icons/ok.png"
                          style={{ width: '30px', height: '30px' }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '32%',

                          borderRadius: '5px',
                        }}
                      >
                        <img
                          src="/assets/icons/agendado.png"
                          style={{ width: '30px', height: '30px' }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: '32%',
                        border: 'solid 1px red',
                        borderRadius: '5px',
                      }}
                    >
                      {renderTotal(index, maquina).somaAtrasadas}
                    </Box>
                    <Box
                      sx={{
                        width: '32%',
                        border: 'solid 1px green',
                        borderRadius: '5px',
                      }}
                    >
                      {renderTotal(index, maquina).somaEmDia}
                    </Box>
                    <Box
                      sx={{
                        width: '32%',
                        border: 'solid 1px blue',
                        borderRadius: '5px',
                      }}
                    >
                      {renderTotal(index, maquina).somaFutura}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: 150,
                      textAlign: 'center',

                      fontWeight: 700,
                      fontSize: {
                        lg: '0.8rem',
                        xl: '0.8rem',
                        xxl: '0.8rem',
                      },
                      m: { lg: 0, xl: 0, xxl: 0 },
                      p: { lg: 0.2, xl: 0.2, xxl: 0.5 },
                    }}
                  >
                    {renderTotal(index, maquina).pesoTotal?.toLocaleString(
                      'pt-BR',
                      {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }
                    )}
                  </Box>
                </Box>
              </Box>
            </Card>
          );
        })}
      </Box>
    );
  }
  return null;
}

import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  IconButton,
  Divider,
  Typography,
  Toolbar,
  CssBaseline,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { AppBar, Drawer, DrawerHeader } from './DrawerComponents';
import ItemListDrawer from './ItemListDrawer/ItemListDrawer';
import UserAvatar from './UserAvatar/UserAvatar';
import { useAuthState } from '../../contexts/AuthContext';
import { Box } from '@mui/system';
import { empresas } from '../../helpers/Empresas';

export default function HeaderDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { usuarioLogado, empresaAtiva } = useAuthState();

  useEffect(() => {
    const empresaEncontrada = empresas.find(
      (empresa) => empresa.id == empresaAtiva
    );

    setNomeEmpresa(empresaEncontrada.nome);
  }, [empresaAtiva]);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            width={'100%'}
            display="flex"
            justifyContent="space-between"
          >
            <Box width={'100%'}>Bem Vindo ao Sistema</Box>
            <Typography component="div" width={'20%'} variant="h6" noWrap>
              Empresa Ativa |
            </Typography>
            <Typography
              component="div"
              width={'20%'}
              variant="h6"
              color="primary.info"
              noWrap
            >
              {nomeEmpresa}
            </Typography>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <UserAvatar open={open} nome={usuarioLogado} />
        <Divider />
        <ItemListDrawer />
      </Drawer>
    </>
  );
}

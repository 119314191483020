import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  Box,
  DialogContentText,
  InputLabel,
} from '@mui/material';

import { useConfirm } from 'material-ui-confirm';
import { CloseTwoTone, SaveTwoTone } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlteraDataEntrega = ({
  openDialogAlteraData,
  onClose,
  producaoSelecionada,
  alteraDataDeEntrega,
  codDuplica,
}) => {
  const confirm = useConfirm();

  const [novaDataDeEntrega, setNovaDataDeEntrega] = useState('');
  const [historico, setHistorico] = useState('');

  const handleClose = () => {
    setHistorico('');
    setNovaDataDeEntrega('');
    onClose();
  };

  const handleConfirm = () => {
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja alterar a data de entrega da produção ?  ',
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    })
      .then(() => {
        alteraDataDeEntrega(
          producaoSelecionada,
          historico,
          novaDataDeEntrega,
          codDuplica
        );
      })

      .catch(() => {
        /* ... */
      });
  };

  return (
    <Dialog
      open={openDialogAlteraData}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>NOVA DATA DE ENTREGA</DialogTitle>
      <DialogContent dividers>
        <DialogContent dividers>
          <DialogContentText>
            ESCOLHA A NOVA DATA DE ENTREGA PARA A PRODUÇÃO :
            {producaoSelecionada}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <InputLabel shrink>SELECIONE A NOVA DATA PARA ENTREGA</InputLabel>
              <TextField
                type={'date'}
                value={novaDataDeEntrega}
                onChange={(e) => setNovaDataDeEntrega(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <TextField
                value={historico}
                onChange={(e) => setHistorico(e.target.value)}
                label="historico"
              />
            </FormControl>
          </Box>
        </DialogContent>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button
          startIcon={<CloseTwoTone />}
          variant="contained"
          onClick={handleClose}
        >
          Fechar
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveTwoTone />}
          onClick={() => {
            handleConfirm();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlteraDataEntrega;

import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';

import { toast } from 'react-toastify';

import api from '../../../../api/api';
import { useAuthState } from '../../../../contexts/AuthContext';
import Spinner from '../../../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../../../components/TitlePage/TitlePage';

import { useConfirm } from 'material-ui-confirm';

import { ImprimePedidoProducao } from '../../../../components/ImprimePedidoProducao/ImprimePedidoProducao';
import SearchComponent from '../../../../components/SearchComponent/SearchComponent';

const ListaProduçoes = () => {
  const { empresaAtiva, acessos, appAcesso, usuarioIDLogado } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [listaOrdens, setListaOrdens] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);

  const [atualizaFiltro, setAtualizaFiltro] = useState(false);
  const [listaMaquinas, setListaMaquinas] = useState([]);

  const [openDialogImprimePedido, setOpenDialogImprimePedido] = useState(false);
  const [producaoSelecionada, setProducaoSelecionada] = useState(0);
  const [listaOrdensFiltradas, setListaOrdensFiltradas] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [parametroFiltro, setParametroFiltro] = useState({
    emissaoInicio: '',
    emissaoFim: '',
    entregaInicio: '',
    entregaFim: '',
    cliente: '',
    status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
    maquina: '',
  });
  const confirm = useConfirm();
  useEffect(() => {
    const buscaDados = async () => {
      setLoading(true);
      try {
        await Promise.all([
          buscarEtapas(),
          buscarClientes(),
          buscarProducoes(),
        ]);
      } catch (error) {
        console.error('Erro ao Buscar Dados:', error);
        toast.error('Erro geral ao buscar dados');
      } finally {
        setLoading(false);
      }
    };

    buscaDados();
  }, [empresaAtiva, atualizaFiltro, parametroFiltro]);

  async function buscarEtapas() {
    try {
      const { data } = await api.get('/producao/lista/etapas', {
        headers: {
          banco: empresaAtiva,
        },
      });

      setListaMaquinas(data);
      return data;
    } catch (error) {
      toast.error('Erro ao buscar Maquinas');
      console.log(error);
    }
  }

  async function buscarProducoes() {
    try {
      const { data } = await api.post('/producao/lista', parametroFiltro, {
        headers: { banco: empresaAtiva },
      });
      setListaOrdens(data);
      setListaOrdensFiltradas(data);
      console.log(data);
      const cidades = data
        .map((ordem) => {
          return ordem.cidade;
        })
        .filter((elem, index, self) => index === self.indexOf(elem))
        .sort();

      setListaCidades(cidades);
    } catch (error) {
      toast.error('Erro ao buscar Produções');
      console.log(error);
    }
  }

  async function buscarClientes() {
    try {
      const { data } = await api.get('/cliente/lista1', {
        headers: {
          banco: empresaAtiva,
        },
      });
      setListaClientes(data);
    } catch (error) {
      toast.error('Erro ao buscar Clientes');
      console.log(error);
    }
  }

  const concluirProducao = (producao, qtde, volume, palete, duplicada) => {
    setLoading(true);
    api
      .put(
        '/producao/finalizar',
        {
          producao: producao,
          quantidade: qtde,
          volume: volume,
          palete: palete,
          usuario: usuarioIDLogado,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(() => {
        setAtualizaFiltro(!atualizaFiltro);
        toast.success('Producão alterada com sucesso ');
        abrirDialogImprimePedido(producao);
      })
      .catch((err) => {
        toast.success('Erro ao  alterar Producao ');
        console.log(err);
        setAtualizaFiltro(!atualizaFiltro);
      })
      .finally(() => setLoading(false));
  };

  const alterarStatus = (producao, statusAtual) => {
    const etapas = [
      'AGUARDANDO',
      'RESERVA',
      'DEFINE ETAPAS',
      'ESTOQUE',
      'PRODUCAO',
      'ACABAMENTO',
      'FINALIZADO',
    ];

    let iNovoStatus = etapas.indexOf(statusAtual) - 1;
    let novoStatus = etapas[iNovoStatus];

    if (statusAtual === 'AGUARDANDO' || statusAtual === 'RESERVA') {
      toast.error('Operacão NÃO PERMITIDA! - O STATUS não permite alteração! ');
    } else {
      confirm({
        title: 'Confirma Operação',
        description: `Deseja Realmente RETROCEDER O STATUS do ${statusAtual}  para o ${novoStatus}`,
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      }).then(() => {
        setLoading(true);
        api
          .put(
            'producao/retroceder/etapas',
            {
              producao: producao,
              status: novoStatus,
              usuario: usuarioIDLogado,
            },
            {
              headers: { banco: empresaAtiva },
            }
          )
          .then(() => {
            toast.success('Producão alterada com sucesso ');
            setAtualizaFiltro(!atualizaFiltro);
          })
          .catch((err) => {
            toast.error('Erro ao  alterar Producao ');
            console.log(err);
            setAtualizaFiltro(!atualizaFiltro);
          })
          .finally(() => setLoading(false));
      });
    }
  };

  const alteraDataDeEntrega = (producao, historico, entrega, duplica) => {
    setLoading(true);
    api
      .put(
        '/producao/entrega/alterar',
        {
          producao: producao,
          historico: historico,
          entrega: entrega,
          usuario: usuarioIDLogado,
          codDuplica: duplica,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(() => {
        toast.success('Data da Entrega Alterada com Sucesso ');
        setAtualizaFiltro(!atualizaFiltro);
      })
      .catch((err) => {
        toast.success('Erro ao  alterar Producao ');
        console.log(err);
        setAtualizaFiltro(!atualizaFiltro);
      })
      .finally(() => setLoading(false));
  };

  const atualizarMaquinas = (values, duplicada) => {
    setLoading(true);
    setAtualizaFiltro(!atualizaFiltro);
    api
      .put(
        '/producao/define/etapas',
        { ...values, usuario: usuarioIDLogado },
        {
          headers: {
            banco: empresaAtiva,
          },
        }
      )
      .then(() => toast.success('Maquinas Adicionadas com sucesso '))
      .catch((err) => {
        toast.error('Erro na Operacão');
        console.log(err);
      })
      .finally(() => setLoading(false), setAtualizaFiltro(!atualizaFiltro));
  };

  const enviaProducaoCarga = (
    producao,
    envia,
    venda,
    codVeiculo,
    duplicada
  ) => {
    if (codVeiculo !== 0) {
      toast.error(
        'Operacão impossível!  -  Produto entregue ou separado para a entrega '
      );
    } else {
      const mensagem = `Deseja ${
        envia === 'SIM' ? 'enviar o pedido para a' : 'retirar o pedido da '
      }  Carga`;
      confirm({
        title: 'Confirma Operação',
        description: mensagem,
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      }).then(() => {
        setLoading(true);
        api
          .put(
            '/producao/enviar/carga',
            {
              producao: producao,
              envia: envia,
              pedido: venda,
              usuario: usuarioIDLogado,
            },
            {
              headers: {
                banco: empresaAtiva,
              },
            }
          )
          .then(() => toast.success('Producão alterada com sucesso '))
          .catch((err) => {
            toast.error('Erro na Operacão');
            console.log(err);
          })
          .finally(() => {
            setAtualizaFiltro(!atualizaFiltro);
            setLoading(false);
          });
      });
    }
  };

  const alterarPrioridade = (producao, prioridade, duplica) => {
    console.log(duplica);
    confirm({
      title: 'Confirme a Operação',
      description: `DESEJA ALTERAR A PRIORIDADE`,
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    })
      .then(() => {
        setLoading(true);
        api
          .put(
            '/producao/prioridade',
            {
              producao: producao,
              usuario: usuarioIDLogado,
              prioridade: prioridade,
              codDuplica: duplica,
            },
            {
              headers: { banco: empresaAtiva },
            }
          )
          .then(() => {
            toast.success('Producão alterada com sucesso ');
            setAtualizaFiltro(!atualizaFiltro);
          })
          .catch((err) => {
            toast.success('Erro ao  alterar Producao ');
            console.log(err);
            setAtualizaFiltro(!atualizaFiltro);
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        /* ... */
      });
  };
  const handleAtualizaFiltro = () => {
    setAtualizaFiltro(!atualizaFiltro);
  };

  const abrirDialogImprimePedido = (producao) => {
    setOpenDialogImprimePedido(true);
    setProducaoSelecionada(producao);
  };

  const fecharDialogImprimePedido = () => {
    setProducaoSelecionada(null);
    setOpenDialogImprimePedido(false);
    setAtualizaFiltro(!atualizaFiltro);
  };

  const filtraOrdensPorCidade = (e) => {
    if (e.target.value === '') {
      setCidadeSelecionada(e.currentTarget.value);
      setAtualizaFiltro(!atualizaFiltro);
    } else {
      setLoading(true);
      setCidadeSelecionada(e.currentTarget.value);
      const novasOrdens = listaOrdens.filter(
        (ordem) => ordem.cidade === e.target.value
      );
      setListaOrdensFiltradas(novasOrdens);
      setLoading(false);
    }
  };
  return (
    <>
      <Box>
        <TitlePage title="Lista de Produções" />
        <Box>
          <Box marginLeft={'6%'}>
            <Typography variant="subtitle2">Período </Typography>
          </Box>
        </Box>
        <SearchComponent
          listaClientes={listaClientes ? listaClientes : []}
          listaMaquinas={listaMaquinas ? listaMaquinas : []}
          handleAtualizaFiltro={handleAtualizaFiltro}
          parametroFiltro={parametroFiltro}
          setParametroFiltro={setParametroFiltro}
          loading={loading}
          filtraOrdensPorCidade={filtraOrdensPorCidade}
          listaCidades={listaCidades}
          cidadeSelecionada={cidadeSelecionada}
          setCidadeSelecionada={setCidadeSelecionada}
        />
        <Divider />
        {producaoSelecionada ? (
          <ImprimePedidoProducao
            open={openDialogImprimePedido}
            onClose={fecharDialogImprimePedido}
            producao={producaoSelecionada}
            tipo="concluir"
          />
        ) : (
          <></>
        )}
        {loading ? (
          <Spinner />
        ) : (
          <TabelaResultados
            nivelAcesso={acessos}
            listaOrdens={listaOrdensFiltradas}
            concluirProducao={concluirProducao}
            alterarStatus={alterarStatus}
            usuario={usuarioIDLogado}
            alteraDataDeEntrega={alteraDataDeEntrega}
            listaMaquinas={listaMaquinas}
            atualizarMaquinas={atualizarMaquinas}
            enviaProducaoCarga={enviaProducaoCarga}
            alterarPrioridade={alterarPrioridade}
          />
        )}
      </Box>
    </>
  );
};

export default ListaProduçoes;

import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  NativeSelect,
  TextField,
  FormControl,
  Box,
  DialogContentText,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReservaMateria = ({
  materiaVincada,
  openDialogSeparaMateria,
  onClose,
  producaoSelecionada,
  listaMateriasPrima,
  atualizaMateriaPrima,
  totalRiscador,
  modeloCaixa,
  totalImpressora,
  qtdPedido,
}) => {
  const [qtde, setQtde] = useState(0);
  const [qtdeCaixasPorChapa, setQtdeCaixasPorChapa] = useState(0);
  const confirm = useConfirm();
  const [materiaPrimaSelecionada, setMateriaPrimaSelecionada] = useState();
  const [estoque, setEstoque] = useState(0);
  const [corte, setCorte] = useState(0);
  const [vincada, setVincada] = useState('NÃO');
  const handleClose = () => {
    setMateriaPrimaSelecionada(0);
    setQtde(0);
    setEstoque(0);
    onClose();
    setQtdeCaixasPorChapa(0);
    setCorte(0);
    setVincada('NÃO');
  };

  const handleConfirm = () => {
    if (qtde > estoque) {
      toast.error(
        'QUANTIDADE SELECIONADA É MAIOR DO QUE O ESTOQUE DISPONÍVEL... VERIFIQUE!'
      );
    } else {
      confirm({
        title: 'Confirme a Operação',
        description: 'Deseja confirmar a conclusão da etapa? ',
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      })
        .then(() => {
          if (materiaPrimaSelecionada && materiaPrimaSelecionada !== 0) {
            atualizaMateriaPrima(
              producaoSelecionada,
              qtde,
              materiaPrimaSelecionada,
              vincada
            );
          } else {
            toast.error('Nenhuma Matéria Prima Selecionada !');
          }
        })

        .catch(() => {});
    }
  };
  const handleSelectMateriaPrima = (codMateria) => {
    if (codMateria != 0) {
      const materiaPrima = listaMateriasPrima.find(
        (materia) => materia.codigo == codMateria
      );

      const novoCoeficiente =
        materiaPrima.coeficiente > 0 ? materiaPrima.coeficiente : 1;
      let novaQuantidade = materiaPrima.qtde_chapas * novoCoeficiente;

      setQtde(novaQuantidade);

      setQtdeCaixasPorChapa(materiaPrima.qtde_caixas_chapa);
      setEstoque(materiaPrima.estoque_total - materiaPrima.estoque_reservado);
      setMateriaPrimaSelecionada(codMateria);
    } else {
      setQtde(0);
      setEstoque(0);
      setMateriaPrimaSelecionada(0);
      setQtdeCaixasPorChapa(0);
      setCorte(0);
      setVincada('NÃO');
    }
  };

  const handleCheckVinco = (event) => {
    if (event.target.checked) {
      setVincada('SIM');
    } else {
      setVincada('NÃO');
    }
  };

  useEffect(() => {
    setCorte(`${totalRiscador} X  ${totalImpressora} - ${modeloCaixa}`);
    if (materiaVincada === 'SIM') {
      setVincada('SIM');
    } else {
      setVincada('NÃO');
    }
  }, [totalImpressora, totalRiscador]);

  return (
    <Dialog
      open={openDialogSeparaMateria}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>RESERVA DE MATERIAL</DialogTitle>
      <DialogContent dividers>
        <DialogContent dividers>
          <DialogContentText>
            ESCOLHA A MATERIA PRIMA A SER UTILIZADA NA PRODUÇÃO :
            {producaoSelecionada}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Box mt={2} mb={2}>
            <Box mt={2} mb={2}>
              <FormControl sx={{ width: '80%' }}>
                <TextField
                  label="CORTE "
                  type="text"
                  value={corte}
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl sx={{ width: '20%' }}>
                {' '}
                <TextField
                  label="QTDE "
                  type="text"
                  value={qtdPedido}
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Box>
            <FormControl fullWidth>
              <InputLabel shrink>SELECIONE A MATERIA PRIMA </InputLabel>
              <NativeSelect
                variant="filled"
                value={materiaPrimaSelecionada}
                onChange={(e) => handleSelectMateriaPrima(e.target.value)}
              >
                <option value={0}>Selecione</option>

                {listaMateriasPrima.length > 0
                  ? listaMateriasPrima.map((materiaPrima) => {
                      return (
                        <option
                          value={materiaPrima.codigo}
                          key={materiaPrima.codigo}
                        >
                          {materiaPrima.descricao}
                          {` (${
                            materiaPrima.estoque_total -
                            materiaPrima.estoque_reservado
                          } Chapas) -  ${
                            materiaPrima.perda
                              ? materiaPrima.perda.toFixed(2)
                              : ''
                          } `}
                        </option>
                      );
                    })
                  : ''}
              </NativeSelect>
            </FormControl>
            <Box mt={5} mb={2}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  value={estoque}
                  label="Estoque Disponivel"
                />
              </FormControl>
            </Box>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <TextField
                label="QUANTIDADE "
                type="number"
                value={qtde}
                onChange={(e) => setQtde(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <TextField
                label="QUANTIDADE DE CAIXAS POR CHAPA "
                type="number"
                value={qtdeCaixasPorChapa}
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>

          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <FormControlLabel
                label="VINCADO"
                control={
                  <Checkbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                    onChange={(e) => handleCheckVinco(e)}
                    checked={vincada === 'NÃO' ? false : true}
                    label={'VINCADO?'}
                  />
                }
              />
            </FormControl>
          </Box>
        </DialogContent>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={handleClose}>Fechar</Button>
        <Button
          onClick={() => {
            handleConfirm();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservaMateria;

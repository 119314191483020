import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  Box,
  DialogContentText,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConcluirProducao = ({
  modalConcluirProducao,
  fecharModalConcluirProducao,
  producaoSelecionada,
  concluirEtapa,
  proximaMaquina,
}) => {
  const [qtde, setQtde] = useState(1);
  console.log(producaoSelecionada);
  return (
    <Dialog
      open={modalConcluirProducao}
      TransitionComponent={Transition}
      keepMounted
      onClose={fecharModalConcluirProducao}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>BAIXA PRODUÇÃO</DialogTitle>
      <DialogContent dividers>
        <DialogContent dividers>
          <DialogContentText>
            DESEJA CONCLUIR A ETAPA PARA A PRODUÇÃO : {producaoSelecionada}
          </DialogContentText>
        </DialogContent>
        {proximaMaquina && (
          <DialogContent sx={{ background: 'red' }}>
            <Typography variant="h6" sx={{ color: 'white' }}>
              ENVIAR PARA A MAQUINA:{' '}
              {proximaMaquina.nome ? proximaMaquina.nome : ''}
            </Typography>
          </DialogContent>
        )}
        <Box mt={2} mb={2}></Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button variant="contained" onClick={fecharModalConcluirProducao}>
          <img
            src="/assets/icons/cancelar.png "
            width="30px"
            style={{ padding: '5px' }}
          />{' '}
          Fechar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            concluirEtapa(producaoSelecionada, qtde);
          }}
        >
          <img
            src="/assets/icons/salvar.png "
            width="30px"
            style={{ padding: '5px' }}
          />
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConcluirProducao;

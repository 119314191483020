import { useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Collapse,
  Box,
  Table,
  TableBody,
  Badge,
} from '@mui/material';
import moment from 'moment';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PhotoIcon from '@mui/icons-material/Photo';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TimerIcon from '@mui/icons-material/Timer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
export default function Row(props) {
  const {
    ordem,
    abrirDialogCadCliche,
    abrirDialogVisualizarImagem,
    abrirDialogAdiarCliche,
    abrirDialogStatusCliche,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={ordem.opr_obs ? { bgcolor: 'primary.infoRow' } : { bgcolor: '' }}
      >
        <TableCell colSpan={2} sx={{ width: '15%', padding: 0, margin: 0 }}>
          <IconButton
            mr={3}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <Tooltip title="Finaliza Clicheria">
            <IconButton
              sx={{ color: 'primary.main' }}
              onClick={() =>
                abrirDialogCadCliche(ordem.opr_cd_cliente, ordem.opr_cd_caixa)
              }
            >
              <NoteAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Visualizar Imagem">
            <IconButton
              mr={3}
              aria-label="expand row"
              size="small"
              onClick={() =>
                abrirDialogVisualizarImagem(
                  ordem.opr_cd_caixa,
                  ordem.opr_cd_cliente
                )
              }
            >
              <PhotoIcon sx={{ color: 'primary.buttonColorSecondary' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Adiar Cliche">
            <IconButton
              mr={3}
              aria-label="expand row"
              size="small"
              onClick={() =>
                abrirDialogAdiarCliche(ordem.opr_cd_cliente, ordem.opr_cd_caixa)
              }
            >
              <TimerIcon
                sx={
                  ordem.cliche_atraso === 'SIM'
                    ? { color: 'primary.error' }
                    : { color: 'primary.buttonColorSecondary' }
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Alterar Status  Cliche">
            <IconButton
              mr={3}
              aria-label="expand row"
              size="small"
              onClick={() =>
                abrirDialogStatusCliche(
                  ordem.opr_cd_cliente,
                  ordem.opr_cd_caixa
                )
              }
            >
              <MoveToInboxIcon sx={{ color: 'primary.buttonColorSecondary' }} />
            </IconButton>
          </Tooltip>

          {ordem.carga === 'SIM' ? (
            <IconButton aria-label="expand row" size="small">
              <Tooltip title="Enviar Pedido para Carga">
                <LocalShippingIcon
                  sx={
                    ordem.carga === 'NÃO'
                      ? { color: 'primary.error' }
                      : { color: 'primary.success' }
                  }
                />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
          <Badge
            badgeContent={ordem.prioridade}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            overlap="circular"
            color={
              ordem.prioridade === 1
                ? 'error'
                : ordem.prioridade === 2
                ? 'warning'
                : ordem.prioridade === 3
                ? 'success'
                : ordem.prioridade === 4
                ? 'info'
                : ordem.prioridade === 5
                ? 'primary'
                : 'primary'
            }
          >
            <FactoryIcon color="primary" />
          </Badge>
        </TableCell>
        <TableCell>{ordem.opr_cd_venda}</TableCell>
        <TableCell>
          {moment(ordem.dataEmissao).add(3, 'hours').format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>
          {moment(ordem.dataEntrega).add(3, 'hours').format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>{ordem.opr_cliche_status}</TableCell>
        <TableCell>
          {ordem.nome_fantasia ? ordem.nome_fantasia : ordem.razao_social}
        </TableCell>
        <TableCell>{ordem.descricao}</TableCell>
        <TableCell>{ordem.cpr_mod_caixa}</TableCell>
        <TableCell>{ordem.quantidade}</TableCell>
        <TableCell>{ordem.cliche}</TableCell>
        <TableCell>{ordem.papelao}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong style={{ marginRight: '3%' }}>PREVISÃO:</strong>
                      {ordem.previsaoEntrega}
                      <strong style={{ marginRight: '3%', marginLeft: '3%' }}>
                        MOTIVO:
                      </strong>
                      {ordem.motivoAtraso}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>VENDEDOR: </strong> {ordem.vendedor}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>MATRIZ: </strong>
                      {ordem.matriz}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <span style={{ marginRight: '3%' }}>
                        <strong>COMPRIMENTO: </strong> {ordem.comprimento}
                      </span>
                      <span style={{ marginRight: '3%' }}>
                        <strong>LARGURA:</strong> {ordem.largura}
                      </span>
                      <span style={{ marginRight: '3%' }}>
                        <strong>ALTURA:</strong> {ordem.altura}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

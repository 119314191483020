import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import { toast } from 'react-toastify';

import { useAuthState } from '../../../../../contexts/AuthContext';

import api from '../../../../../api/api';

import Row from './Rows/Row';

import ConcluirProducao from './ConcluirProducao/ConcluirProducao';
import VisualizarImagem from './VisualizarImagem/VisualizarImagem';
import AlteraDataEntrega from './AlteraDataEntrega/AlteraDataEntrega';
import Spinner from '../../../../../components/Spinner/Spinner';
import SelecionaMaquinas from './SelecionaMaquinas/SelecionaMaquinas';
import AlteraPrioridade from './AlteraPrioridade/AlteraPrioridade';
import { useConfirm } from 'material-ui-confirm';

export default function TabelaResultados({
  listaOrdens,
  concluirProducao,
  alterarStatus,
  alteraDataDeEntrega,
  nivelAcesso,
  atualizarMaquinas,
  listaMaquinas,
  enviaProducaoCarga,
  usuario,
  alterarPrioridade,
}) {
  const confirm = useConfirm();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialogConcluirProducao, setOpenDialogConcluirProducao] =
    useState(false);
  const { empresaAtiva } = useAuthState();
  const [producaoSelecionada, setProducaoSelecionada] = useState('');
  const [openDialogVisualizaImagem, setOpenDialogVisualizaImagem] =
    useState(false);
  const [imagemSelecionada, setImagemSelecionada] = useState();
  const [openDialogAlteraData, setOpenDialogAlteraData] = useState(false);
  const [openDialogAlteraPrioridade, setOpenDialogAlteraPrioridade] =
    useState(false);
  const [openDialogSelecionaMaquinas, setOpenDialogSelecionaMaquinas] =
    useState(false);
  const [codDuplica, setCodigoDuplica] = useState(0);
  const [qtdeCaixasProducaoSelecionada, setQtdeCaixasProducaoSelecionada] =
    useState(0);

  const [maquinasSelecionadas, setMaquinasSelecionadas] = useState([]);
  const [statusAtual, setStatusAtual] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const fechadialogConcluirProducao = () => {
    setOpenDialogConcluirProducao(false);
  };
  const handleOpenDialogConcluirProducao = (
    producao,
    idDuplica,
    qtdeCaixas
  ) => {
    setProducaoSelecionada(producao);
    setQtdeCaixasProducaoSelecionada(qtdeCaixas);
    setOpenDialogConcluirProducao(true);
  };
  const fechaDialogVisualizaImagem = () => {
    setOpenDialogVisualizaImagem(false);
    setImagemSelecionada(null);
  };
  const handleOpenDialogVisualizaImagem = () => {
    setOpenDialogVisualizaImagem(true);
  };
  const handleOpenDialogAlteraData = (producao, duplicada) => {
    if (duplicada !== 0) {
      confirm({
        title: 'Alerta!',
        description: `Existe uma ou mais ordens vinculadas a esse pedido, Verifique!  `,
        confirmationText: 'OK',
        cancellationText: 'CANCELAR',
      });
      setCodigoDuplica(duplicada);
    }
    setProducaoSelecionada(producao);
    setOpenDialogAlteraData(true);
  };

  const handleOpenDialogAlteraPrioridade = (producao, duplicada) => {
    if (duplicada !== 0) {
      confirm({
        title: 'Alerta!',
        description: `Existe uma ou mais ordens vinculadas a esse pedido, Verifique!  `,
        confirmationText: 'OK',
        cancellationText: 'CANCELAR',
      });
      setCodigoDuplica(duplicada);
    }
    setProducaoSelecionada(producao);
    setOpenDialogAlteraPrioridade(true);
  };

  const handleCloseDialogAlteraPrioridade = () => {
    setProducaoSelecionada('');
    setOpenDialogAlteraPrioridade(false);
    setCodigoDuplica(0);
  };

  const handleCloseDialogAlteraData = () => {
    setProducaoSelecionada('');
    setOpenDialogAlteraData(false);
    setCodigoDuplica(0);
  };

  const handleOpenDialogAlteraMaquinas = (
    producao,
    maquinas,
    status,
    duplicada
  ) => {
    if (duplicada !== 0) {
      confirm({
        title: 'Alerta!',
        description: `Existe uma ou mais ordens vinculadas a esse pedido, Verifique!  `,
        confirmationText: 'OK',
        cancellationText: 'CANCELAR',
      });
    }
    setProducaoSelecionada(producao);
    setOpenDialogSelecionaMaquinas(true);
    setMaquinasSelecionadas(maquinas);
    setStatusAtual(status);
  };
  const handleCloseDialogSelecionaMaquinas = () => {
    setProducaoSelecionada('');
    setOpenDialogSelecionaMaquinas(false);
    setStatusAtual('');
    setMaquinasSelecionadas([]);
  };

  const functionVisualizarImagem = (caixa, cliente) => {
    setLoading(true);
    api
      .post(
        '/imagem/arte',
        { item: caixa, cliente: cliente },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setImagemSelecionada(data.artes[0].imagem);
      })
      .catch((err) => {
        toast.error('Imagem não encontrada ');
      })
      .finally(() => {
        handleOpenDialogVisualizaImagem();
        setLoading(false);
      });
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <Box>
      {producaoSelecionada ? (
        <AlteraPrioridade
          open={openDialogAlteraPrioridade}
          onClose={handleCloseDialogAlteraPrioridade}
          producaoSelecionada={producaoSelecionada}
          alterarPrioridade={alterarPrioridade}
          codDuplica={codDuplica}
        />
      ) : (
        <></>
      )}
      {producaoSelecionada ? (
        <ConcluirProducao
          openDialogConcluirProducao={openDialogConcluirProducao}
          onClose={fechadialogConcluirProducao}
          producaoSelecionada={producaoSelecionada}
          concluirProducao={concluirProducao}
          qtdeCaixasProducaoSelecionada={qtdeCaixasProducaoSelecionada}
        />
      ) : (
        <></>
      )}

      {imagemSelecionada ? (
        <VisualizarImagem
          onClose={fechaDialogVisualizaImagem}
          openDialogVisualizaImagem={openDialogVisualizaImagem}
          imagemSelecionada={imagemSelecionada}
          usuario={usuario}
        />
      ) : (
        <></>
      )}

      {producaoSelecionada ? (
        <AlteraDataEntrega
          openDialogAlteraData={openDialogAlteraData}
          onClose={handleCloseDialogAlteraData}
          alteraDataDeEntrega={alteraDataDeEntrega}
          producaoSelecionada={producaoSelecionada}
          codDuplica={codDuplica}
        />
      ) : (
        <></>
      )}
      {maquinasSelecionadas.length > 0 ? (
        <SelecionaMaquinas
          open={openDialogSelecionaMaquinas}
          onClose={handleCloseDialogSelecionaMaquinas}
          listaMaquinas={listaMaquinas}
          maquinasSelecionadas={maquinasSelecionadas}
          atualizarMaquinas={atualizarMaquinas}
          producaoSelecionada={producaoSelecionada}
          statusAtual={statusAtual}
        />
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Qtde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        visualizarImagem={functionVisualizarImagem}
                        concluiProducao={handleOpenDialogConcluirProducao}
                        alteraDataEntrega={handleOpenDialogAlteraData}
                        alterarStatus={alterarStatus}
                        alteraMaquinas={handleOpenDialogAlteraMaquinas}
                        alteraPrioridade={handleOpenDialogAlteraPrioridade}
                        nivelAcesso={nivelAcesso}
                        enviaProducaoCarga={enviaProducaoCarga}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}

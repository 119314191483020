import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  Box,
} from '@mui/material';

import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdiaCliche = ({
  open,
  onClose,
  clienteSelecionado,
  caixaSelecionada,
  adiarCliche,
}) => {
  const [motivo, setMotivo] = useState('');
  const [novaData, setNovaData] = useState(moment().format('YYYY-MM-DD'));

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>CLICHERIA</DialogTitle>
      <DialogContent dividers sx={{ width: '450px' }}>
        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="Digite o Motivo pelo qual o Clichê está atrasado"
              type="string"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
            />
          </FormControl>
        </Box>

        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="Digite a Data"
              type="date"
              value={novaData}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setNovaData(e.target.value)}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => {
            adiarCliche(clienteSelecionado, caixaSelecionada, motivo, novaData);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdiaCliche;

import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';
import { useAuthState } from '../../../../../contexts/AuthContext';
import { toast } from 'react-toastify';

import api from '../../../../../api/api';
import Row from './Rows/Row';
import CadCliche from './CadCliche/CadCliche';
import VisualizarImagem from './VisualizarImagem/VisualizarImagem';
import Spinner from '../../../../../components/Spinner/Spinner';
import AdiaCliche from './AdiaCliche/AdiaCliche';
import StatusCliche from './StatusCliche/StatusCliche';
export default function TabelaResultados({
  listaOrdens,
  atualizaStatus,

  adiarCliche,
  alterarStatusCliche,
}) {
  const { empresaAtiva } = useAuthState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialogCadCliche, setOpenDialogCadCliche] = useState(false);
  const [openDialogAdiaCliche, setOpenDialogAdiaCliche] = useState(false);
  const [openDialogStatusCliche, setOpenDialogStatusCliche] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState('');
  const [caixaSelecionada, setCaixaSelecionada] = useState('');
  const [imagemSelecionada, setImagemSelecionada] = useState();
  const [loading, setLoading] = useState(false);
  const [openDialogVisualizaImagem, setOpenDialogVisualizaImagem] =
    useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const abrirDialogCadCliche = (cliente, caixa) => {
    setClienteSelecionado(cliente);
    setCaixaSelecionada(caixa);
    setOpenDialogCadCliche(true);
  };

  const fechaDialogCadCliche = () => {
    setClienteSelecionado(0);
    setCaixaSelecionada(0);
    setOpenDialogCadCliche(false);
  };

  const abrirDialogStatusCliche = (cliente, caixa) => {
    setClienteSelecionado(cliente);
    setCaixaSelecionada(caixa);
    setOpenDialogStatusCliche(true);
  };

  const fechaDialogStatusCliche = () => {
    setClienteSelecionado(0);
    setCaixaSelecionada(0);
    setOpenDialogStatusCliche(false);
  };
  const abrirDialogAdiarCliche = (cliente, caixa) => {
    setClienteSelecionado(cliente);
    setCaixaSelecionada(caixa);
    setOpenDialogAdiaCliche(true);
  };
  const fechaDialogAdiaCliche = (cliente, caixa) => {
    setClienteSelecionado(cliente);
    setCaixaSelecionada(caixa);
    setOpenDialogAdiaCliche(false);
  };

  const abrirDialogVisualizarImagem = (caixa, cliente) => {
    setLoading(true);
    api
      .post(
        '/imagem/arte',
        { item: caixa, cliente: cliente },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setImagemSelecionada(data.artes[0].imagem);
        setOpenDialogVisualizaImagem(true);
      })
      .catch((err) => {
        toast.error('Imagem não encontrada ');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fechaDialogVisualizaImagem = () => {
    setOpenDialogVisualizaImagem(false);
    setImagemSelecionada(null);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box>
      {clienteSelecionado ? (
        <CadCliche
          open={openDialogCadCliche}
          onClose={fechaDialogCadCliche}
          caixaSelecionada={caixaSelecionada}
          clienteSelecionado={clienteSelecionado}
          atualizaStatus={atualizaStatus}
        />
      ) : (
        <></>
      )}
      {imagemSelecionada ? (
        <VisualizarImagem
          onClose={fechaDialogVisualizaImagem}
          openDialogVisualizaImagem={openDialogVisualizaImagem}
          imagemSelecionada={imagemSelecionada}
        />
      ) : (
        <></>
      )}
      {clienteSelecionado ? (
        <AdiaCliche
          open={openDialogAdiaCliche}
          onClose={fechaDialogAdiaCliche}
          caixaSelecionada={caixaSelecionada}
          clienteSelecionado={clienteSelecionado}
          adiarCliche={adiarCliche}
        />
      ) : (
        <></>
      )}
      {clienteSelecionado ? (
        <StatusCliche
          open={openDialogStatusCliche}
          onClose={fechaDialogStatusCliche}
          caixaSelecionada={caixaSelecionada}
          clienteSelecionado={clienteSelecionado}
          alterarStatusCliche={alterarStatusCliche}
        />
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell>Pedido</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Status Cliche</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell>Qtde</TableCell>
              <TableCell>Cliche</TableCell>
              <TableCell>Papelão</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        abrirDialogCadCliche={abrirDialogCadCliche}
                        abrirDialogStatusCliche={abrirDialogStatusCliche}
                        abrirDialogAdiarCliche={abrirDialogAdiarCliche}
                        abrirDialogVisualizarImagem={
                          abrirDialogVisualizarImagem
                        }
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}

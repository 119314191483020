import axios from 'axios';

const api = axios.create({
  baseURL: 'https://speed-industria-node.herokuapp.com/',
  //baseURL: 'http://192.168.0.85:3333', // rene
  //baseURL: 'http://192.168.0.14:3333', // RENAN
  //baseURL: 'http://192.168.0.222:3333',   // lh
  // baseURL: 'http://localhost:3333', // lh
  //baseURL: 'http://192.168.0.155:3333/',
});

export default api;

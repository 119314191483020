import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisualizarImagem = ({
  modalVisualizaImagem,
  fecharModalVisualizaImagem,
  imagemSelecionada,
}) => {
  return (
    <Dialog
      open={modalVisualizaImagem}
      TransitionComponent={Transition}
      keepMounted
      onClose={fecharModalVisualizaImagem}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent dividers>
        {imagemSelecionada !== '-1' ? (
          <img
            src={`data:image/jpg;base64,${imagemSelecionada}`}
            width="100%"
            height="100%"
            alt="Caixa"
          />
        ) : (
          <></>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={fecharModalVisualizaImagem}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisualizarImagem;

import React, { useEffect, useState } from 'react';
import {
  NativeSelect,
  TextField,
  Box,
  FormControl,
  Stack,
  Divider,
  Typography,
  Button,
} from '@mui/material';

import { toast } from 'react-toastify';
import moment from 'moment';

import api from '../../../../api/api';

import { useAuthState } from '../../../../contexts/AuthContext';
import Spinner from '../../../../components/Spinner/Spinner';
import TitlePage from '../../../../components/TitlePage/TitlePage';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import RefreshIcon from '@mui/icons-material/Refresh';

const Clicheria = () => {
  const { empresaAtiva, usuarioIDLogado } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [listaOrdens, setListaOrdens] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [listaOrdensFiltradas, setListaOrdensFiltradas] = useState([]);
  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [tipoFiltro, setTipoFiltro] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get('/producao/lista/cliche', {
        headers: { banco: empresaAtiva },
      })
      .then(({ data }) => {
        setListaOrdens(data);
        setListaOrdensFiltradas(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      })
      .finally(() => setLoading(false));
  }, [empresaAtiva, atualizaFiltro]);

  useEffect(() => {
    api
      .get('/cliente/lista1', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados do cliente', data)
      );
  }, [empresaAtiva]);

  const atualizaStatus = (cliente, caixa, numCliche, corCliche) => {
    if (!cliente || !caixa || !numCliche) {
      toast.error('Preencha os campos corretamente');
    } else {
      setLoading(true);

      api
        .put(
          '/producao/finalizar/cliche',
          {
            codigoCliente: cliente,
            codigoCaixa: caixa,
            usuario: usuarioIDLogado,
            numero: numCliche,
            cor: corCliche,
          },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(() => {
          toast.success('Producão alterada com sucesso ');
          setAtualizaFiltro(!atualizaFiltro);
        })
        .catch((err) => {
          toast.error('Erro ao  alterar Produção ');
          console.log(err);
          setAtualizaFiltro(!atualizaFiltro);
        })
        .finally(() => setLoading(false));
    }
  };
  const adiarCliche = (cliente, caixa, motivo, novaData) => {
    console.log(cliente, caixa);
    if (!cliente || !motivo || !novaData) {
      toast.error('Preencha os campos corretamente');
    } else {
      setLoading(true);

      api
        .put(
          '/producao/atraso/cliche',
          {
            codigoCliente: cliente,
            codigoCaixa: caixa,
            motivo: motivo,
            data: novaData,
            usuario: usuarioIDLogado,
          },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(() => {
          toast.success('Producão alterada com sucesso ');
          setAtualizaFiltro(!atualizaFiltro);
        })
        .catch((err) => {
          toast.success('Erro ao  alterar Producao ');
          console.log(err);
          setAtualizaFiltro(!atualizaFiltro);
        })
        .finally(() => setLoading(false));
    }
  };

  const alterarStatusCliche = (cliente, caixa, status) => {
    if (!cliente || !status) {
      toast.error('Preencha os campos corretamente');
    } else {
      setLoading(true);

      api
        .put(
          '/producao/status/cliche',
          {
            codigoCliente: cliente,
            codigoCaixa: caixa,

            status: status,
            usuario: usuarioIDLogado,
          },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(() => {
          toast.success('Producão alterada com sucesso ');
          setAtualizaFiltro(!atualizaFiltro);
        })
        .catch((err) => {
          toast.success('Erro ao  alterar Producao ');
          console.log(err);
          setAtualizaFiltro(!atualizaFiltro);
        })
        .finally(() => setLoading(false));
    }
  };

  const filtraListaOrdens = (tipoFiltro, cliente) => {
    if (tipoFiltro === 'cliente' && cliente === '') {
      setClienteSelecionado('');
      setListaOrdensFiltradas(listaOrdens);
      setDataFim(moment().format('YYYY-MM-DD'));
      setDataInicio(moment().format('YYYY-MM-DD'));
      return;
    }
    let ordensFiltradas = [];
    switch (tipoFiltro) {
      case 'emissao':
        ordensFiltradas = listaOrdens.filter(
          (ordem) =>
            moment(ordem.dataEmissao).add(3, 'hours') >= moment(dataInicio) &&
            moment(ordem.dataEmissao).add(3, 'hours') <= moment(dataFim)
        );
        setTipoFiltro('emissao');

        setListaOrdensFiltradas(ordensFiltradas);
        break;
      case 'entrega':
        ordensFiltradas = listaOrdens.filter(
          (ordem) =>
            moment(ordem.dataEntrega).add(3, 'hours') >= moment(dataInicio) &&
            moment(ordem.dataEntrega).add(3, 'hours') <= moment(dataFim)
        );
        setListaOrdensFiltradas(ordensFiltradas);
        setTipoFiltro('entrega');
        break;
      case 'cliente':
        ordensFiltradas = listaOrdens.filter(
          (ordem) => ordem.opr_cd_cliente == cliente
        );
        setDataFim(moment().format('YYYY-MM-DD'));
        setDataInicio(moment().format('YYYY-MM-DD'));
        setClienteSelecionado(cliente);
        setListaOrdensFiltradas(ordensFiltradas);
        break;
      default:
        setAtualizaFiltro(!atualizaFiltro);
        setTipoFiltro('');
        setDataFim(moment().format('YYYY-MM-DD'));
        setDataInicio(moment().format('YYYY-MM-DD'));
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box>
        <TitlePage title="Clicheria" />
        <Box>
          <Box marginLeft={'6%'}>
            <Typography variant="subtitle2">Período </Typography>
          </Box>

          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 1, md: 3 }}
            mt={2}
            mb={2}
          >
            <Box width={'4%'} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={() => setAtualizaFiltro(!atualizaFiltro)}
                sx={{
                  borderRadius: '50%',
                  width: '25%',
                  height: '100%',
                  backgroundColor: 'primary.success',
                }}
              >
                <RefreshIcon sx={{ color: 'white' }} />
              </Button>
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Inicial "
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Final"
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Box>

            <Box width={'20%'}>
              <Box>
                <Typography variant="subtitle2">Filtra por Data</Typography>
              </Box>
              <NativeSelect
                value={tipoFiltro}
                fullWidth
                onChange={(e) => filtraListaOrdens(e.target.value)}
              >
                <option default value="">
                  Todos os Registros
                </option>
                <option value="emissao">Por Emissao</option>
                <option value="entrega">Por Data Entrega </option>
              </NativeSelect>
            </Box>
            <Box width={'45%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Cliente</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  fullWidth
                  value={clienteSelecionado}
                  onChange={(e) => filtraListaOrdens('cliente', e.target.value)}
                >
                  <option value="">Todos os Registros </option>
                  {listaClientes.length > 0 ? (
                    listaClientes.map((cliente) => {
                      return (
                        <option key={cliente.codigo} value={cliente.codigo}>
                          {cliente.nome_fantasia
                            ? cliente.nome_fantasia
                            : cliente.razao_social}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <TabelaResultados
          listaOrdens={listaOrdensFiltradas}
          atualizaStatus={atualizaStatus}
          adiarCliche={adiarCliche}
          alterarStatusCliche={alterarStatusCliche}
        />
      </Box>
    </>
  );
};

export default Clicheria;

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Stack,
  FormControl,
  Typography,
  NativeSelect,
  Autocomplete,
  Button,
} from '@mui/material';
import { useAuthState } from '../../contexts/AuthContext';
import RefreshIcon from '@mui/icons-material/Refresh';

import moment from 'moment';

const SearchComponent = (props) => {
  const {
    handleAtualizaFiltro,
    listaClientes,
    listaMaquinas,
    setParametroFiltro,
    filtraOrdensPorCidade,
    cidadeSelecionada,
    setCidadeSelecionada,
    listaCidades,
    loading,
  } = props;

  const { appAcesso } = useAuthState();
  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [maquinaFiltroSelecionada, setMaquinaFiltroSelecionada] = useState('');

  const [clienteSelecionado, setClienteSelecionado] = useState({});
  const [statusFiltroSelecionado, setStatusFiltroSelecionado] = useState('');
  const [tipoFiltro, setTipoFiltro] = useState('');

  const handleSelectData = (e, tipo) => {
    if (tipo == 'DataInicio') {
      setDataInicio(e);
    }
    if (tipo == 'DataFim') {
      setDataFim(e);
    }
  };
  const selecionaFiltro = (tipoFiltro) => {
    switch (tipoFiltro.tipoFiltro) {
      case 'emissao':
        setParametroFiltro((prevState) => ({
          ...prevState,
          emissaoInicio: dataInicio,
          emissaoFim: dataFim,
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
          maquina: '',
          buscaMaquina: '',
        }));
        break;

      case 'entrega':
        setParametroFiltro((prevState) => ({
          ...prevState,
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: dataInicio,
          entregaFim: dataFim,
          cliente: '',
          status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
          maquina: '',
          buscaMaquina: '',
        }));
        break;
      case 'cliente':
        setParametroFiltro((prevState) => ({
          ...prevState,
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: tipoFiltro.codigo_cliente,
          status: statusFiltroSelecionado,
          maquina: '',
          buscaMaquina: '',
        }));
        break;
      case 'maquina':
        setParametroFiltro((prevState) => ({
          ...prevState,
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: '',
          maquina: tipoFiltro.maquina,
          buscaMaquina: 'SIM',
        }));
        break;
      case 'status':
        setParametroFiltro((prevState) => ({
          ...prevState,
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: clienteSelecionado.codigo_cliente,
          status: tipoFiltro.status,
          maquina: '',
          buscaMaquina: 'SIM',
        }));
        break;
      default:
        setParametroFiltro((prevState) => ({
          ...prevState,
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
          maquina: '',
          buscaMaquina: '',
        }));
    }

    setTipoFiltro(tipoFiltro.tipoFiltro);
    setMaquinaFiltroSelecionada(tipoFiltro.maquina);

    setStatusFiltroSelecionado((prevState) => {
      if (tipoFiltro.status !== undefined && tipoFiltro.status !== null) {
        return tipoFiltro.status;
      }
      return prevState;
    });

    setClienteSelecionado((prevState) => {
      if (tipoFiltro == 'cliente') {
        return tipoFiltro;
      }
      return prevState;
    });
  };

  return (
    <Stack
      direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
      spacing={{ xs: 1, sm: 1, md: 3 }}
      mt={2}
      mb={2}
    >
      <Box width={'4%'} display={'flex'} justifyContent={'center'}>
        <Button
          onClick={handleAtualizaFiltro}
          sx={{
            borderRadius: '50%',
            width: '25%',
            height: '100%',
            backgroundColor: 'primary.success',
          }}
        >
          <RefreshIcon sx={{ color: 'white' }} />
        </Button>
      </Box>
      <Box width={'13%'}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Emissão Inicial "
          type="date"
          variant="standard"
          size="medium"
          value={dataInicio}
          disabled={loading}
          onChange={(e) => handleSelectData(e.target.value, 'DataInicio')}
        />
      </Box>
      <Box width={'13%'}>
        <TextField
          fullWidth
          size="medium"
          InputLabelProps={{ shrink: true }}
          label="Emissão Final"
          type="date"
          variant="standard"
          value={dataFim}
          disabled={loading}
          onChange={(e) => handleSelectData(e.target.value, 'DataFim')}
        />
      </Box>
      <Box width={'20%'}>
        <Box>
          <Typography variant="subtitle2">Filtro por Data</Typography>
        </Box>
        <NativeSelect
          value={tipoFiltro}
          fullWidth
          disabled={loading}
          size="small"
          onChange={(e) => selecionaFiltro({ tipoFiltro: e.target.value })}
        >
          <option default value={''}>
            Todos os Registros
          </option>
          <option value={'emissao'}>Por Emissao</option>
          <option value={'entrega'}>Por Data Entrega </option>
        </NativeSelect>
      </Box>
      <Box width={'45%'}>
        <Box>
          <Typography variant="subtitle2">Filtrar por Cliente</Typography>
        </Box>

        <FormControl fullWidth>
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              onChange={(event, newValue) => {
                if (newValue) {
                  selecionaFiltro({
                    tipoFiltro: 'cliente',
                    codigo_cliente: newValue.codigo_cliente,
                    nome_fantasia: newValue.nome_fantasia,
                    value: newValue.value,
                  });
                } else {
                  // Lidar com o caso de limpar a seleção
                  selecionaFiltro({
                    tipoFiltro: 'cliente',
                    codigo_cliente: 0,
                    nome_fantasia: '',
                    value: null,
                  });
                }
              }}
              options={listaClientes.map((cliente) => ({
                codigo_cliente: cliente.codigo,
                nome_fantasia: cliente.nome_fantasia
                  ? cliente.nome_fantasia
                  : cliente.razao_social,
                value: cliente.codigo,
              }))}
              getOptionLabel={(option) => option.nome_fantasia}
              renderInput={(params) => (
                <TextField {...params} variant="standard" size="small" />
              )}
            />
          </FormControl>

          {/* <NativeSelect
            fullWidth
            size="small"
            value={clienteSelecionado}
            onChange={(e) =>
              selecionaFiltro({
                tipoFiltro: "cliente",
                cliente: e.target.value,
              })
            }
          >
            <option value={""}>Todos os Registros </option>
            {listaClientes.length > 0 ? (
              listaClientes.map((cliente) => {
                return (
                  <option key={cliente.codigo} value={cliente.codigo}>
                    {cliente.nome_fantasia
                      ? cliente.nome_fantasia
                      : cliente.razao_social}
                  </option>
                );
              })
            ) : (
              <></>
            )}
            </NativeSelect>*/}
        </FormControl>
      </Box>
      <Box width={'25%'}>
        <Box>
          <Typography variant="subtitle2">Filtrar por Maquina</Typography>
        </Box>

        <FormControl fullWidth>
          <NativeSelect
            disabled={loading}
            size="small"
            fullWidth
            value={parseInt(maquinaFiltroSelecionada)}
            onChange={(e) =>
              selecionaFiltro({
                tipoFiltro: 'maquina',
                maquina: e.target.value,
              })
            }
          >
            <option value="0">Todos os Registros </option>
            {listaMaquinas.length > 0 ? (
              listaMaquinas.map((maquina) => {
                return (
                  <option key={maquina.maq_codigo} value={maquina.maq_codigo}>
                    {maquina.maq_descricao}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box width={'25%'}>
        <Box>
          <Typography variant="subtitle2">Filtrar por Status</Typography>
        </Box>

        <FormControl fullWidth>
          <NativeSelect
            disabled={loading}
            size="small"
            fullWidth
            value={statusFiltroSelecionado}
            onChange={(e) =>
              selecionaFiltro({
                tipoFiltro: 'status',
                status: e.target.value,
              })
            }
          >
            <option value={''}>Todos os Registros </option>

            <option value={'ACABAMENTO'}>ACABAMENTO</option>

            <option value={'AGUARDANDO'}>AGUARDANDO</option>

            <option value={'CLICHERIA'}>CLICHERIA</option>

            <option value={'COMPRA'}>COMPRA</option>

            <option value={'DEFINE ETAPAS'}>DEFINE ETAPAS</option>

            <option value={'ESTOQUE'}>ESTOQUE</option>

            <option value={'PRODUCAO'}>PRODUCAO</option>

            <option value={'RESERVA'}>RESERVA</option>

            <option value={'FINALIZADO'}>FINALIZADO</option>
          </NativeSelect>
        </FormControl>
      </Box>
      <Box width={'25%'}>
        <Box>
          <Typography variant="subtitle2">Filtrar por Cidade</Typography>
        </Box>
        <FormControl>
          <NativeSelect
            disabled={loading}
            size="small"
            fullWidth
            onChange={filtraOrdensPorCidade}
            value={cidadeSelecionada}
          >
            <option value={''}>Todos os Registros </option>

            {listaCidades &&
              listaCidades.map((cidade) => (
                <option value={cidade}>{cidade}</option>
              ))}
          </NativeSelect>
        </FormControl>
      </Box>
    </Stack>
  );
};
export default SearchComponent;
